import React, { Component } from 'react'
import WalletHistoryTabPanel from '../../../components/Tabs/WalletHistoryTabPanel'

class WalletHistoryTab extends Component {
    render() {
        return (
            <div>
              <WalletHistoryTabPanel />
            </div>
        )
    }
}
export default WalletHistoryTab;