import React, { Component } from 'react'
import ApprovedLoanTabPanel from '../../../components/Tabs/AprrovedLoanTabPanel'

class ApprovedLoanTab extends Component {
    render() {
        return (
            <div>
              <ApprovedLoanTabPanel />
            </div>
        )
    }
}
  export default ApprovedLoanTab;