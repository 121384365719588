export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    SAVINGS_REQUEST: 'USERS_SAVINGS_REQUEST',
    SAVINGS_SUCCESS: 'USERS_SAVINGS_SUCCESS',
    SAVINGS_FAILURE: 'USERS_SAVINGS_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    PASSWORD_REQUEST: 'USERS_PASSWORD_REQUEST',
    PASSWORD_SUCCESS: 'USERS_PASSWORD_SUCCESS',
    PASSWORD_FAILURE: 'USERS_PASSWORD_FAILURE',

    
    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'    
};
