import React, { Component } from 'react'
import AwaitingLoanTabPanel from '../../../components/Tabs/AwaitingLoanTabPanel'

class AwaitingLoanTab extends Component {
    render() {
        return (
            <div>
              <AwaitingLoanTabPanel />
            </div>
        )
    }
}

  export default AwaitingLoanTab;